
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditProductCategoryModal from "@/components/modals/referencials/product-categories/EditProductCategoryModal.vue";

interface IPagination {
    page  : number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-product-categories",
    props: {
        widgetClasses: String,
    },
    components: {
        EditProductCategoryModal,
    },
    setup() {
        const { t, te } = useI18n();
        let loading = ref(false);
        let activeProductCategory = ref(null);
        const productCategories = ref([]);
        const pagination = ref<IPagination>({
            page  : 1,
            total : 0,
            offset: 10,
        });

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const goPage = (page: number) => {
            pagination.value.page = page;

            getProductCategories();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getProductCategories();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getProductCategories();
        };

        const create = () => {
            activeProductCategory.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_product_category'));
            modal.show();
        };

        const edit = (productCategory) => {
            activeProductCategory.value = productCategory;
            const modal = new Modal(document.getElementById('kt_modal_edit_product_category'));
            modal.show();
        };

        const remove = (productCategory, level) => {
            const code = productCategory[`productCategoryL${level}Code`];

            if (code) {

                axios.delete(`${ApiRoutes.productCategories.delete}/${code}`)
                    .then((response: AxiosResponse) => {

                        if (response.data.success) {

                            Swal.fire({
                                text: response.data.message,
                                icon: "success",
                                buttonsStyling: false,
                                confirmButtonText: translate("confirm"),
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                            }).then(() => {
                                refresh();
                            });
                        } else {

                            Swal.fire({
                                text: response.data.message,
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: translate("confirm"),
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                            });
                        }
                    })
                    .catch((error) => {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    });

            } else {

                Swal.fire({
                    text: translate("productCategoryDoesNotExist"),
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: translate("confirm"),
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                });
            }
        };

        const confirmRemove = (productCategory, level) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("productCategoryConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(productCategory, level);
                }
            });
        };

        const getProductCategories = async () => {
            loading.value = true;

            await axios.get(ApiRoutes.productCategories.index, { params: pagination.value })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.total = response.data.total;

                    productCategories.value = response.data.rows;
                }).catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        }

        onMounted(() => {
            getProductCategories();

            setCurrentPageBreadcrumbs(translate("productCategoryHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("stockManagementHeading")
            ]);
        });

        return {
            translate,
            productCategories,
            pagination,
            activeProductCategory,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
