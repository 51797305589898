
import * as Yup from "yup";
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "edit-productCategory-modal",
    emit: ["refreshData"],
    props: {
        data: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editProductCategoryModalRef = ref<null | HTMLElement>(null);

        let errors: any = ref();
        const productCategoryL1Names = ref([]);
        const productCategoryL2Names = ref([]);
        const productCategoryL3Names = ref([]);
        const productCategoryL4Names = ref([]);

        const productCategoryDefault = {
            productCategoryL1Name: "",
            productCategoryL1Description: "",
            productCategoryL2Name: "",
            productCategoryL2Description: "",
            productCategoryL3Name: "",
            productCategoryL3Description: "",
            productCategoryL4Name: "",
            productCategoryL4Description: "",
        };
        const productCategoryData = ref<any>({});
        Object.assign(productCategoryData.value, productCategoryDefault);

        const validationSchema = Yup.object().shape({
            // productCategoryL1Name: Yup.string().required().label(translate("productCategoryL1NameLabel")),
            // productCategoryL1Description: Yup.string().nullable().label(translate("productCategoryL1DescriptionLabel")),
            // productCategoryL2Name: Yup.string().nullable().label(translate("productCategoryL2NameLabel")),
            // productCategoryL2Description: Yup.string().nullable().label(translate("productCategoryL2DescriptionLabel")),
            // productCategoryL3Name: Yup.string().nullable().label(translate("productCategoryL3NameLabel")),
            // productCategoryL3Description: Yup.string().nullable().label(translate("productCategoryL3DescriptionLabel")),
            // productCategoryL4Name: Yup.string().nullable().label(translate("productCategoryL4NameLabel")),
            // productCategoryL4Description: Yup.string().nullable().label(translate("productCategoryL4DescriptionLabel")),
        });

        const submit = () => {
            errors.value = null;

            disableSubmitButton(submitButtonRef);

            axios.post(ApiRoutes.productCategories.updateOrCreate, productCategoryData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            hideModal(editProductCategoryModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const getProductCategoriesNames = async () => {
            let response = await axios.get(ApiRoutes.productCategories.names, { params: { level: 1 } });
            productCategoryL1Names.value = response.data;

            response = await axios.get(ApiRoutes.productCategories.names, { params: { level: 2 } });
            productCategoryL2Names.value = response.data;

            response = await axios.get(ApiRoutes.productCategories.names, { params: { level: 3 } });
            productCategoryL3Names.value = response.data;

            response = await axios.get(ApiRoutes.productCategories.names, { params: { level: 4 } });
            productCategoryL4Names.value = response.data;
        };

        onMounted(() => {
            const modal = document.getElementById('kt_modal_edit_product_category');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {

                for (var key in productCategoryData.value) {
                    productCategoryData.value[key] = "";
                }
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                errors.value = null;

                if (props.data && productCategoryData.value) {

                    // Fill productCategory fields
                    Object.keys(productCategoryData.value).forEach((key) => {
                        productCategoryData.value[key] = props.data[key];
                    });
                }
            });

            getProductCategoriesNames();
        });

        return {
            translate,

            submitButtonRef,
            editProductCategoryModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            productCategoryData,
            productCategoryL1Names,
            productCategoryL2Names,
            productCategoryL3Names,
            productCategoryL4Names,

            submit,
        };
    },
});
